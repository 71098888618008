/* === INPUT === */

[component='input'] {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid var(--color-neutral-300);
    background-color: var(--color-white);
    font-size: 16px;
    padding: 0px 2px;
    overflow: hidden;
    min-height: 40px;
    width: 100%;
}

[component='input']:focus-within {
    border-color: var(--color-outline);
}

[component='input'] > input {
    appearance: none;
    border: none;
    border-radius: inherit;
    padding: 8px;
    height: 100%;
    width: 100%;
}

[component='input'] > input::placeholder {
    color: var(--color-neutral-400);
}

[component='input'] > input:focus {
    outline: none;
}

[component='input'] > .input-icon {
    appearance: none;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    margin: 0px;
    border-radius: inherit;
    padding: 8px;
}

[component='input'] > .input-icon > [component='icon'] {
    height: 20px;
    width: 20px;
}

/* === SLIDER === */

[component="slider"] {
    display: none;
    position: relative;
    width: 97%;
}

[component='slider'].open {
    display: block;
    position: fixed;
    overflow: hidden;
    left: 0;
    top: 0px;
    height: 100%;
    z-index: 12;
}

[component='slider'].open::before {
    content: '';
    background-color: var(--color-black);
    opacity: 0.6;
    position: absolute;
    height: 100%;
    width: 100%;
}

[component='slider'] .container {
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    width: 100%;
}

[component='slider'].open .container {
    border-top-left-radius: var(--space-sm);
    border-top-right-radius: var(--space-sm);
    background-color: var(--color-white);
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 15%;
    bottom: 0px;
    height: auto;
    width: 100%;
}

[component='slider'] .container .handle {
    display: none;
    cursor: grab;
    background-color: var(--color-neutral-200);
    border-radius: 4px;
    margin: 4px auto;
    height: 4px;
    width: 60px;
}

[component='slider'] .container .handle:active {
    cursor: grabbing;
}

[component='slider'].open .container .handle {
    display: block;
}

[component='slider'] .container .title {
    border-bottom: 1px solid var(--color-neutral-200);
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    padding: 16px;
}

/* === COMBOBOX === */

[component='combobox'] {
    font-size: 16px;
    position: relative;
    width: 100%;
}

[component='combobox'] [component="slider"] {
    display: block;
}

[component='combobox'].open .container .input {
    border-color: transparent;
}

[component='combobox'].open .container .input:focus-within {
    border-color: transparent;
}

[component='combobox'] .input .button-clear,
[component='combobox'] .input .button-close,
[component='combobox'] .input .button-search,
[component='combobox'].open .input .button-close,
[component='combobox'].open .input .button-open {
    display: none;
}

[component='combobox'] .input .button-open,
[component='combobox'].open .input .button-search {
    display: block;
}

[component='combobox'] [role='listbox'] {
    display: block;
    appearance: none;
    list-style: none;
    flex: 1 1;
    margin-top: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    display: none;
    position: static;
    height: auto;
    width: 100%;
}

[component='combobox'] [role='listbox'] > [role='option'] {
    font-weight: 400;
    background-color: var(--color-white);
    border: 1px solid transparent;
    font-weight: 400;
    cursor: pointer;
    padding: 8px 16px;
}

[component='combobox'] [role='listbox'] > [role='option']:hover {
    background-color: var(--color-neutral-100);
}

[component='combobox'] [role='listbox'] > [role='option']:focus {
    border-color: var(--color-outline) !important;
    outline: none;
}

@media (max-height: 350px) {
    [component='combobox'].open .container {
        top: 10px;
    }
}

@media (min-width: 640px) {
    [component='combobox'].open::before {
        content: none;
        display: none;
    }

    [component='combobox'].open .container {
        border: none;
        background-color: transparent;
        padding-top: 0px;
        display: block;
        position: relative;
        top: 0px;
        height: 100%;
        width: 100%;
    }

    [component='combobox'].open .container .input {
        border-color: var(--color-neutral-300);
    }

    [component='combobox'].open .container .input:focus-within {
        border-color: var(--color-outline);
    }

    [component='combobox'] .input .button-search,
    [component='combobox'] .input .button-close,
    [component='combobox'].open .input .button-search,
    [component='combobox'].open .input .button-open {
        display: none;
    }

    [component='combobox'] .input .button-open,
    [component='combobox'].open .input .button-close {
        display: block;
    }

    [component='combobox'] [role='listbox'] {
        border-radius: var(--space-xs);
        border: 1px solid var(--color-neutral-100);
        background-color: var(--color-white);
        box-shadow:
                0px 4px 6px 0px rgba(0, 0, 0, 0.05),
                0px 10px 15px -3px rgba(0, 0, 0, 0.1);
        position: absolute;
        z-index: 2;
        margin-bottom: 0px;
        margin-top: 4px;
        max-height: 180px;
        width: 100%;
    }

    [component='combobox'] [role='listbox'] > [role='option'] {
        background-color: var(--color-white);
        border-radius: var(--space-xs);
    }

    [component='combobox'] [role='listbox'] > [role='option']:hover {
        background-color: var(--color-neutral-100);
    }

    [component='combobox']
    [role='listbox']
    > [role='option']
    + [role='option'] {
        border-top-color: transparent;
    }

    [component='combobox'] [component="slider"],
    [component='combobox'] [component="slider"].open {
        position: static;
        overflow: visible;
        height: auto;
        width: auto;
        z-index: 1;
    }

    [component='combobox'] [component='slider'].open::before {
        content: none;
    }

    [component='combobox'] [component='slider'] .container .handle,
    [component='combobox'] [component='slider'].open .container .handle {
        display: none;
    }
}

/* === CHECKBOX === */

[component='checkbox'] {
    display: flex;
    align-items: center;
    margin: 0px;
    gap: var(--space-sm);
    font-weight: 400;
}

[component='checkbox'] > input {
    appearance: none;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid var(--color-neutral-300);
    border-radius: 4px;
    margin: 0px;
    height: 20px;
    width: 20px;
}

[component='checkbox'] > input:hover {
    border-color: var(--color-primary);
}

[component='checkbox'] > input:checked {
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 9.75L6.39645 13.1464C6.59171 13.3417 6.90829 13.3417 7.10355 13.1464L15 5.25' stroke='white' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

[component='checkbox'] > input:checked:focus {
    border-color: var(--color-primary);
}

[component='checkbox'] > input:focus {
    outline: none;
    border-color: var(--color-outline);
}
